import React from "react"
import Pagelayout from "../layouts/page"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import { Col, Row, Button, Accordion} from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

// styles
const Pageheading = styled.h1`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  margin: 10px 0 50px 0;
  font-weight: 400;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.font.headings};
`

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colours.common.white};
  background-color: ${({ theme }) => theme.colours.primary.main};
  border-color: #ba2117;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  padding: 5px 20px;

  &:hover, :active, .active, .focus, :focus, :not(:disabled):not(.disabled).active, :not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle  {
    color: ${({ theme }) => theme.colours.common.white};
    background-color: ${({ theme }) => theme.colours.common.black};
    border-color: ${({ theme }) => theme.colours.common.black};
    font-family: ${({ theme }) => theme.font.headings};
    text-transform: uppercase;
    padding: 5px 20px;
  }

  &:focus, :focus {
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colours.primary.light};
  }
`

const Contentcontainer = styled(Col)`
  background-color: ${({ theme }) => theme.colours.common.white};
  margin-top: 0px;
  padding: 35px;
  margin-bottom: 50px;

  @media (min-width: 576px) { 
    padding: 25px;
  }

  @media (min-width: 992px) { 
    margin-top: 25px;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
  }

  & h1 {
    color: ${({ theme }) => theme.colours.primary.main};
    font-weight: 400;
    font-family: ${({ theme }) => theme.font.headings};
    text-transform: uppercase;
    margin-bottom: 15px;
    font-size: 2rem;
  }

  & strong {
    margin-top: 30px;
    display: block;
    font-size: 1.2rem;
    font-family: ${({ theme }) => theme.font.headings};
  }

`
const Accordtitle = styled(Accordion.Header)`
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.headings};
  display: block;

  & .accordion-button:not(.collapsed) {
    color: ${({ theme }) => theme.colours.primary.main};
    background-color: inherit;
    border-left: solid 5px ${({ theme }) => theme.colours.primary.main};
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  & .accordion-button {
    font-size: 1.5rem;
    text-transform: uppercase;
  }
  
  & .accordion-button:not(.collapsed)::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c63127'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
  }
    
  }

`

const ServicesDetailsPage = ({ data }) => {

 const faqNode = data.directus.faq;
 
 return (
  <>
      <Seo title={`${data.directus.settings.site_title} · ${data.directus.services_by_id.title}`} description={data.directus.services_by_id.seo_description} author={data.directus.settings.site_author}  />
      <Pagelayout logo={data.directus.settings.site_logo} footerimage={data.directus.settings.footer_image} >
          <Row>
            <Col>
                  <Pageheading>{data.directus.services_by_id.title}</Pageheading>
            </Col>
          </Row>
          <Row>
              <Col sm={12} lg={6} className="serviceImg"><GatsbyImage image={data.directus.services_by_id.image1.imageFile.childImageSharp.gatsbyImageData} alt={data.directus.services_by_id.image1.title} objectPosition="center center" /></Col>
              <Contentcontainer sm={12} lg={6} className="details">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.directus.services_by_id.content1,
                  }}
                />
              </Contentcontainer>
          </Row>
          <Row>
            <Col sm={12} lg={6} className="serviceImg d-none d-sm-block d-lg-none"><GatsbyImage image={data.directus.services_by_id.image2.imageFile.childImageSharp.gatsbyImageData} alt={data.directus.services_by_id.image2.title} objectPosition="center center" /></Col>
            <Contentcontainer sm={12} lg={6} className="details">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.directus.services_by_id.content2,
                  }}
                />
              </Contentcontainer>
              <Col sm={12} lg={6} className="serviceImg d-none d-lg-block"><GatsbyImage image={data.directus.services_by_id.image2.imageFile.childImageSharp.gatsbyImageData} alt={data.directus.services_by_id.image2.title} objectPosition="center center" /></Col>
          </Row>
          <Row>
              <Col sm={12} lg={6} className="serviceImg">
              {
                data.directus.services_by_id.image3 && data.directus.services_by_id.image3.imageFile  ? <GatsbyImage image={data.directus.services_by_id.image3.imageFile.childImageSharp.gatsbyImageData} alt={data.directus.services_by_id.image3.title} objectPosition="center center" /> : <p></p>
              }
              </Col>
              
              <Contentcontainer sm={12} lg={6} className="details">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.directus.services_by_id.content3,
                  }}
                />
              </Contentcontainer>
          </Row>
  
          <Row>
            <Col>
                  <Pageheading>Frequently Asked Questions (FAQ)</Pageheading>
            </Col>
          </Row>
          <Row>
          <Accordion defaultActiveKey="0" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
          {faqNode.map((faqItem, i) => 
          {
            if (faqItem.servicelink.id === data.directus.services_by_id.id) {
              return (
              <>
              <Accordion.Item eventKey={i} key={i}>
                <Accordtitle itemprop="name">{faqItem.question}</Accordtitle>
                <Accordion.Body itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer" dangerouslySetInnerHTML={{
                          __html: faqItem.answer,
                      }} />
              </Accordion.Item>
              </> )
            }
            return null
            
            
            
          })}
          </Accordion>
          </Row>
      </Pagelayout>
      </>
)
        } 

export default ServicesDetailsPage

export const query = graphql`
query ($id: ID!) {
  directus {
    settings {
      site_title
      site_description
      site_author
      site_logo {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
      footer_image {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    }
    faq(filter: {status: {_eq: "published"}}) {
      question
      answer
      status
      servicelink {
        id
      }
    }
    services_by_id(id: $id) {
      title
      seo_description
      content1
      content2
      content3
      image1 {
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: WEBP)
          }
        }
        title
        id
      }
      image2 {
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: WEBP)
          }
        }
        title
        id
      }
      image3 {
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: WEBP)
          }
        }
        title
        id
      }
      id
    }
  }
} 
`